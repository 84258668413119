import React, { Component } from 'react';
import CodenamesLobby from './CodenamesLobby';
import GameRoom from './GameRoom';

import fetch2 from '../fetch2'

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory
  } from "react-router-dom";
import GameRoomLobby from './GameRoomLobby';


class Codenames extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Router>
                <Switch>
                    <Route exact path="/">
                        <CodenamesLobby />
                    </Route>
                    <Route  exact path="/room/:room_id"
                            breadcrumb="Game Room" 
                            component={GameRoom} >
                    </Route>
                </Switch>
            </Router>
        );
    }

}

export default Codenames;