import React, { Component } from 'react';
import './Card.css';

class Card extends Component {

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        const selected = this.props.selected;
        if (selected) {
            return;
        }

        this.setState(state => ({
            selected: true
        }));

        const codeword = this.props.codeword;
        const handleCardSelect = this.props.onCardSelect;
        handleCardSelect(codeword);
    }

    render() {
        const codeword = this.props.codeword;
        const identity = this.props.identity;
        const selected = this.props.selected;

        const className = selected ? identity : "unrevealed";
        return (
            <button className={className} onClick={this.handleClick}>
                {codeword}
            </button>
        );
    }

}

export default Card;