import firebase from 'firebase';
const firebaseConfig = {
    apiKey: "AIzaSyA4_bmdmS0fRyD0CwmpBIe1K1GqC2-HK6g",
    authDomain: "anthman-firebase.firebaseapp.com",
    projectId: "anthman-firebase",
    storageBucket: "anthman-firebase.appspot.com",
    messagingSenderId: "971754486320",
    appId: "1:971754486320:web:53c384b45fbfde06884bf5",
    measurementId: "G-1N6RMBQ5ZK"
  };
const fire = firebase.initializeApp(firebaseConfig);
export default fire;