import React, { Component } from 'react';

class Scoreboard extends Component {

    render() {

        const blue_score = this.props.blue_score;
        const red_score = this.props.red_score;
        const whos_turn = this.props.whos_turn;

        const turn_text = whos_turn + "'s turn";

        return (
            <div>
                {blue_score} - {red_score}
            </div>
        );

    }

}

export default Scoreboard;