import React, { Component } from 'react';
import Card from './Card';

class Board extends Component {

    render() {

        const deck = this.props.deck;
        if (!deck) { 
            return(<div></div>); 
        }
        const handleCardSelect = this.props.onCardSelect;


        let cards = new Array();
        deck.forEach((attrs, codeword) => {
            const identity = deck.get(codeword)['identity'];
            const selected = deck.get(codeword)['selected'];

            cards.push(
                <Card   key={codeword} 
                    codeword={codeword} 
                    identity={identity} 
                    selected={selected} 
                    onCardSelect={handleCardSelect} />
            )
        });
        
        const cards0 = cards.slice(0,5);
        const cards1 = cards.slice(5,10);
        const cards2 = cards.slice(10,15);
        const cards3 = cards.slice(15,20);
        const cards4 = cards.slice(20,25);

        return(
            <div>
                <p className="row">{cards0}</p>
                <p className="row">{cards1}</p>
                <p className="row">{cards2}</p>
                <p className="row">{cards3}</p>
                <p className="row">{cards4}</p>
            </div>
        );
    }

}

export default Board;