import logo from './logo.svg';
import './App.css';
import React, { Component } from 'react';

import Codenames from './codenames/Codenames';

class App extends Component {

  constructor(props) {

      super(props);
      this.state = { 
        apiResponse: ""
      };
  }

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>Hey Lucy, I love you.</p>
          <Codenames />
        </header>
        <p className="App-Intro"></p>
      </div>
    );
  }

}

export default App;
