import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import fetch2 from '../fetch2';
import './CodenamesLobby.css'

class CodenamesLobby extends Component {

    constructor(props) {
        super(props);
        this.validateRoomID = this.validateRoomID.bind(this);

        this.state = {
            room_id: "404",
            room_exists: false
        };
    }

    validateRoomID(event) {
        const room_id = event.target.value;
        const room_exists = (room_id == "jarjar");
        this.setState({
            room_id: room_id,
            room_exists: room_exists
        });
        return room_exists;
    }

    render() {
        const room_id = this.state.room_id;
        const action_route = '/room/' + room_id;
        const action_text = (this.state.room_exists) ? "Join This Room" : "Create This Room";
        const action_class = (this.state.room_exists) ? "joinRoom" : "createRoom";

        return (
            <div>
                <input className="Room ID" type="text" placeholder="Enter Room ID" onChange={this.validateRoomID} /><p />
                <Link className={action_class} to={action_route}>{action_text}</Link>
            </div>
        );
    }

}

export default CodenamesLobby;