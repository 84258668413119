const _apiHost = 'http://localhost:9000'

function objectToQueryString(obj) {
    return Object.keys(obj).map(key => key + '=' + obj[key]).join('&');
}

async function fetch2(route, params = {}, method='GET') {

    const options = { 
        method,
        headers: {
            'Content-Type': 'application/json'
        }
    };

    let stitch_params = objectToQueryString(params);
    let stitch_url = route;

    if (params) {
        if (method === 'GET') {
            stitch_url += '?' + stitch_params;
            console.log(stitch_url);
        } else {
            options.body = JSON.stringify(stitch_params);
        }
    }

    const response = await fetch(_apiHost + stitch_url, options);
    const result = await response.json();
    console.log(result);
    return result;
}

export default fetch2;