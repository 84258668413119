import React, { Component } from 'react';

class GameRoomLobby extends Component {

    render() {
        return <p>Game Room Lobby</p>;
    }

}

export default GameRoomLobby;