import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import GameRoomLobby from './GameRoomLobby';
import Scoreboard from './Scoreboard';
import Board from './Board';

import fetch2 from '../fetch2';
import fire from '../fire';

class GameRoom extends Component {

    constructor(props) {
        super(props);

        const {room_id} = this.props.match.params;

        const first_player = "red";
        const red_score = 9;
        const blue_score = 8;

        this.state = {
            room_id: room_id,
            match_counter: 0,
            red_wins: 0,
            blue_wins: 0,
            used_words: {},

            red_score: red_score,
            blue_score: blue_score,
            first_player: first_player,
            whos_turn: first_player,
        };

        this.handleCardSelect = this.handleCardSelect.bind(this);
    }

    async componentDidMount() {
        console.log(this.state.deck);
        if (!this.state.deck) {
            const deck = await this.dealNewDeck();
            this.setState({
                deck: deck
            });
        }
    }

    async dealNewDeck() {
        console.log(this.state.deck);
        const deckArr = await fetch2('/deck', {
            room_id: 1,
            whos_first: "red"
        }, 'GET');
        let deck = new Map();
        deckArr.forEach((entry) => {
            const codeword = entry[0];
            const attrs = entry[1];
            deck.set(codeword, attrs);
        });
        return deck;
    }

    handleCardSelect(codeword) {
        const deck = this.state.deck;
        const card = deck.get(codeword);
        card['selected'] = true;
        deck.set(codeword, card);
        

        let red_score = this.state.red_score;
        let blue_score = this.state.blue_score;

        const identity = deck.get(codeword)['identity'];
        if (identity === "blue") {
            blue_score--;
        } else if (identity === "red") {
            red_score--;
        }

        this.setState({
            deck: deck,
            blue_score: blue_score,
            red_score: red_score
        });
    }

    render() {
        const deck = this.state.deck;
        if (!deck) return <div></div>;
        
        const red_score = this.state.red_score;
        const blue_score = this.state.blue_score;

        return (
            <div>
                <Scoreboard blue_score={blue_score} 
                            red_score={red_score} 
                            whos_turn={'red'} />
                <Board  deck={deck} 
                        onCardSelect={this.handleCardSelect} />
                <Link to="/" class="backButton">Back</Link>
            </div>
        );
    }

}

export default GameRoom;